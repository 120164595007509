<template>
  <div>
    <div class="login-new">
          <div class="left-login-new">
              <div class="left-login-new-center">
                <div class="left-login-new-center-top">
                  <img src="../../../public/img/login-fon-img.png" alt />
                </div>
                <img class="img-bez" src="../../../public/img/bez.png" alt="">
              </div>
          </div>
          <div class="right-login-new">
            <img class="login-logo" src="../../../public/img/gohr_new.png" alt="">
            <div class="lrft_form">
              <el-form ref="form" :model="form" :rules="rules">
                <div class="app-form__group mb-5">
                  <el-form-item prop="phone_number">
                    <el-input             
                      placeholder="998"
                      v-model="form.phone_number"
                      prefix-icon="el-icon-user"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="app-form__group mb-5">
                  <el-form-item prop="password">
                    <el-input
                      placeholder="Пароль"
                      type="password"
                      show-password
                      v-model="form.password"
                      prefix-icon="el-icon-lock"
                    ></el-input>
                  </el-form-item>
                </div>
                <el-button type="success" @click="login()" :loading="loading" plain>{{
                  $t("message.login")
                }}</el-button>
              </el-form>
            </div>
          </div>
      </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";
export default {
  name: "login",
  data() {
    return {
      user: [],
      phone_number: "",
      form: {
        password: "",
        phone_number: 998,
      },

      validCredentials: {
        username: "lightscope",
        password: "lightscope",
      },
      valid: {
        phone_number: true,
        confirm_code: false,
        password: false,
      },
      model: {
        valid_phone: "",
        confirm_code: null,
        password: "",
        confirm_password: "",
      },
      loading: false,
      loading_validate: false,
      dialogVisible: false,
      rules: {
        phone_number: [
          {
            required: true,
            message: "Пожалуйста, введите телефон",
            trigger: "blur",
          },
          {
            min: 12,
            max: 12,
            message: "Длина должна быть 13",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Пожалуйста, введите телефон",
            trigger: "blur",
          },
          {
            min: 5,
            message: "Длина должна быть 5",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },    
  },
  computed: {
    ...mapGetters({
      token: "auth/token",
    }),
  },
  methods: {
    ...mapActions({
      postLogin: "auth/login",
    }),
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    login() {       
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postLogin(this.form)
            .then((res) => {
              console.log('jkhkjdhfjksdh');
              this.loading = false;
              this.$router.push({
                path: this.redirect || "/",
                query: this.otherQuery,
              });
            })
            .catch((error) => {
              console.log(error.data.error.code);
              if (error.data.error.code == 403) {
                // let route = this.$router.resolve({
                //   name: "payment"
                // });
                this.$router.push({name: 'payment'})
                // window.open(route.href);
                
                this.loading = false;
              }
              else{
                this.loading = false;

                this.$alert(error);
              }

           
            });
        }
      });
    },
    Message_Success(message) {
      this.$notify.success({
        dangerouslyUseHTMLString: true,
        title: "Success",
        message: "<strong>" + message + "</strong>",
        duration: 6000,
      });
    },
    Message_Error(code, message) {
      this.$notify.error({
        dangerouslyUseHTMLString: true,
        title: "Error " + code,
        message: "<strong>" + message + "</strong>",
        position: "top-right",
      });
    },
  },
};
</script>
<style lang="scss">
.login-new{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #080c24 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-login-new{
    width: 65%;
}
.right-login-new{
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}
.left-login-new-center {
  margin: 5vh 0vh 5vh 5vh;
    height: 90vh;
    border-radius: 1vh;
    background: #2f3349;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-bez{
  width: 100%;
    position: absolute;
    bottom: 0;
}
.left-login-new-center-top img{
  height: 77vh;
}
.login-logo{
  width: 300px;
}
.login-text{
    font-size: 16px;
    font-family: Nunito,sans-serif;
    text-align: center;
    color: #fff;
    width: 80%;
    margin-top: 30px;
}
.right-login-new .lrft_form{
    margin-top: 100px;
    width: 80%;
  }
.right-login-new .el-input__inner{
  background-color: #0e2c4685 !important;
  border: 1px dashed #2a2f34;
  color: #ced4da !important;
}
.right-login-new .el-button{
  width: 100%;
  background-color: #0c67c9;
  border-color: #0c67c9;
  border-radius: 3px !important;
  color: #fff;
}
@media only screen and (max-width: 1200px) {
  .left-login-new{
      display: none;
  }
}
</style>
